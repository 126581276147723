import store from '@/store'
import router from '@/router'
import { sendRequest } from './apiService'

export default class UserService {
  static async login ({ username, password }) {
    const url = `${process.env.VUE_APP_API_URL}/access-token`
    const body = { username, password }
    const response = await sendRequest({ url, method: 'POST', body })
    localStorage.setItem('token', response.access)
    store.commit('setUser', response.user)
    store.commit('setLoggedIn', true)
    router.push({ name: 'home' })
  }
}
