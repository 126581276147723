<template>
  <the-header />
  <router-view />
</template>

<script>
import TheHeader from '@/components/layouts/TheHeader'

export default {
  components: {
    TheHeader
  }
}
</script>

<style>
@import url('@/assets/css/style.css');
</style>
