export default {
  state: {
    user: null,
    loggedIn: false
  },
  getters: {
    user: state => state.user,
    loggedIn: state => state.loggedIn
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    setLoggedIn (state, loggedIn) {
      state.loggedIn = loggedIn
    }
  },
  actions: {
    fetchUser ({ commit }) {
      const user = {
        username: 'John Doe',
        permissions: ['CAN_ADD_WORD']
      }
      commit('setUser', user)
    },
    login ({ commit }) {
      commit('setLoggedIn', true)
    },
    logout ({ commit }) {
      commit('setLoggedIn', false)
    }
  }
}
