import { createStore } from 'vuex'
import users from './modules/users'
import words from './modules/words'
import dictionary from './modules/dictionary'

export default createStore({
  modules: {
    users,
    words,
    dictionary
  }
})
