import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import HomeView from '@/views/HomeView.vue'
import { UserPermissionEnum } from '@/enums'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/words/add',
    name: 'wordAdd',
    component: () => import('@/views/WordAdd.vue'),
    meta: {
      permissions: [UserPermissionEnum.CAN_ADD_WORD]
    }
  },
  {
    path: '/words/:id',
    name: 'wordDetail',
    component: () => import('@/views/WordDetail.vue')
  },
  {
    path: '/words/:id/change',
    name: 'wordChange',
    component: () => import('@/views/WordChange.vue'),
    meta: {
      permissions: [UserPermissionEnum.CAN_EDIT_WORD]
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/ResultsView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, _, next) => {
  const user = store.getters.user
  const permissions = to.meta.permissions
  if (permissions && !user) {
    return next({ name: 'login' })
  } else if (
    permissions &&
    user &&
    !permissions.some(permission => user.permissions.includes(permission))
  ) {
    return next({ name: 'login' })
  }
  next()
})

export default router
