export default {
  state: {
    srcLanguage: localStorage.getItem('srcLanguage') || 'ky',
    trgLanguage: localStorage.getItem('trgLanguage') || 'ky'
  },
  getters: {
    srcLanguage: state => state.srcLanguage,
    trgLanguage: state => state.trgLanguage
  },
  mutations: {
    setSrcLanguage (state, language) {
      localStorage.setItem('srcLanguage', language)
      state.srcLanguage = language
    },
    setTrgLanguage (state, language) {
      localStorage.setItem('trgLanguage', language)
      state.trgLanguage = language
    }
  },
  actions: {}
}
