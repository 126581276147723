import store from '@/store'

// Permissions helper function to check if user has permissions
const hasPermissions = requiredPermissions => {
  const user = store.getters.user
  const permissions = user ? user.permissions : []
  return requiredPermissions.some(permission =>
    permissions.includes(permission)
  )
}

export { hasPermissions }
