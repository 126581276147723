<template>
  <div class="container">
    <nav>
      <router-link to="/" class="link-item link-item--logo">
        <img class="logo" :src="require('@/assets/logo.svg')" alt="Logo" />
      </router-link>
      <div class="search-input-box">
        <input
          type="text"
          name="query"
          autocomplete="off"
          :placeholder="t('placeholders.search')"
          v-model="query"
          @input="findWordTips"
        />
        <button @click="search">
          <i class="mask-icon mask-icon-38-38 mask-icon-search"></i>
        </button>
        <div :class="wordTipsClass">
          <div
            v-for="tip in tips"
            :key="tip.id"
            class="word-tip"
            @click="getWord(tip.id)"
          >
            {{ tip.word }}
          </div>
        </div>
      </div>
      <select v-model="srcLanguage" class="language-select">
        <option
          :value="language.value"
          v-for="(language, index) in srcLanguages"
          :key="index"
        >
          {{ language.label }}
        </option>
      </select>
      <select v-model="trgLanguage" class="language-select">
        <option
          :value="language.value"
          v-for="(language, index) in trgLanguages"
          :key="index"
        >
          {{ language.label }}
        </option>
      </select>
      <router-link
        :to="{ name: 'wordAdd' }"
        class="link-item link-item--button link-item--add"
        v-if="canAddWord"
      >
        <i class="mask-icon mask-icon-38-38 mask-icon-add"></i>
      </router-link>
      <router-link
        to="/login"
        v-if="!loggedIn"
        class="link-item link-item--button link-item--sign-in"
      >
        <span>{{ t('login') }}</span>
        <i class="mask-icon mask-icon-38-38 mask-icon-sign-in"></i>
      </router-link>
      <a
        href="#"
        class="link-item link-item--button link-item--sign-in"
        @click="logout"
        v-else
      >
        <span>{{ t('logout') }}</span>
        <i class="mask-icon mask-icon-38-38 mask-icon-sign-out"></i>
      </a>
    </nav>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { hasPermissions } from '@/helpers'
import { UserPermissionEnum } from '@/enums'
import { DictionaryService } from '@/services'

const store = useStore()
const router = useRouter()

const { t } = useI18n({ useScope: 'global' })

const query = ref('')
const tips = ref([])
const showQueryTips = ref(false)

const srcLanguages = ref([
  { value: 'ky', label: t('languages.kyrgyz') },
  { value: 'en', label: t('languages.english') }
])

const trgLanguages = ref([
  { value: 'ky', label: t('languages.kyrgyz') },
  { value: 'en', label: t('languages.english') }
])

const srcLanguage = computed({
  get: () => store.getters.srcLanguage,
  set: language => {
    store.commit('setSrcLanguage', language)
  }
})

const trgLanguage = computed({
  get: () => store.getters.trgLanguage,
  set: language => {
    store.commit('setTrgLanguage', language)
  }
})

const loggedIn = computed(() => {
  return store.getters.loggedIn
})

const canAddWord = computed(() => {
  return hasPermissions([UserPermissionEnum.CAN_ADD_WORD])
})

const wordTipsClass = computed(() => {
  return {
    'word-tips': true,
    'word-tips--active': showQueryTips.value
  }
})

const logout = () => {
  localStorage.removeItem('token')
  store.commit('setUser', null)
  store.commit('setLoggedIn', false)
  router.push({ name: 'home' })
}

const search = () => {
  showQueryTips.value = false
  router.push({ name: 'search', query: { q: query.value } })
  query.value = ''
}
const getWord = id => {
  showQueryTips.value = false
  router.push({ name: 'wordDetail', params: { id } })
}
const findWordTips = async () => {
  if (query.value.length > 2) {
    tips.value = await DictionaryService.fetchWordTips(query.value)
    showQueryTips.value = true
  } else {
    tips.value = []
  }
}
</script>

<style scoped>
.language-select {
  width: 120px;
  margin-right: 10px;
  height: 50px;
  outline: none;
}
</style>
