export default {
  state: {
    word: {},
    newWord: null,
    meanings: [],
    translations: {},
    translationIndexToShowAddExampleForm: null,
    translationLanguageCodeToShowAddExampleForm: null,
    meaningIndexToShowAddExampleForm: null
  },
  getters: {
    meanings: state => state.meanings,
    translations: state => state.translations,
    meaningExamples: state => body => {
      if (body === '') return []
      const meaning = state.meanings.find(meaning => meaning.body === body)
      if (!meaning) return []
      return meaning.examples
    },
    translationIndexToShowAddExampleForm: state =>
      state.translationIndexToShowAddExampleForm,
    translationLanguageCodeToShowAddExampleForm: state =>
      state.translationLanguageCodeToShowAddExampleForm,
    meaningIndexToShowAddExampleForm: state =>
      state.meaningIndexToShowAddExampleForm
  },
  mutations: {
    clearMeanings (state) {
      state.meanings = []
    },
    clearTranslations (state) {
      state.translations = {}
    },
    setMeanings (state, meanings) {
      state.meanings = meanings
    },
    setTranslations (state, translations) {
      state.translations = translations
    },
    updateNewWord (state, newWord) {
      if (newWord === '') {
        state.newWord = null
      } else {
        state.newWord = newWord
      }
    },
    addMeaning (state, meaning) {
      state.meanings.push(meaning)
    },
    updateMeaning (state, { meaningIndex, updatedMeaning }) {
      const oldMeaning = state.meanings[meaningIndex]
      if (oldMeaning) {
        state.meanings[meaningIndex] = { ...oldMeaning, ...updatedMeaning }
      } else {
        console.error('Meaning not found at index', meaningIndex)
      }
    },
    deleteMeaning (state, indexToDelete) {
      state.meanings.splice(indexToDelete, 1)
    },
    addMeaningExample (state, { meaningIndex, example }) {
      console.log(meaningIndex)
      const meaning = state.meanings[meaningIndex]
      meaning.examples.push(example)
    },
    updateMeaningExample (state, { meaningIndex, exampleIndex, newExample }) {
      const meaning = state.meanings[meaningIndex]
      if (meaning) {
        meaning.examples.splice(exampleIndex, 1, newExample)
      } else {
        console.error('Meaning not found')
      }
    },
    deleteMeaningExample (state, { meaningIndex, exampleIndex }) {
      const meaning = state.meanings[meaningIndex]
      meaning.examples.splice(exampleIndex, 1)
    },
    addTranslation (state, { language, translation }) {
      state.translations[language] = [
        ...(state.translations[language] || []),
        translation
      ]
    },
    deleteTranslation (state, { language, translationIndex }) {
      state.translations[language].splice(translationIndex, 1)
    },
    addTranslationExample (state, { language, translationIndex, example }) {
      const languageTranslations = state.translations[language]

      if (languageTranslations) {
        const translation = languageTranslations[translationIndex]

        if (translation) {
          translation.examples.push(example)
        } else {
          console.error('Translation not found:', translationIndex)
        }
      } else {
        console.error('Language translations not found:', language)
      }
    },
    deleteTranslationExample (
      state,
      { language, translationIndex, exampleIndex }
    ) {
      const translation = state.translations[language][translationIndex]
      translation.examples.splice(exampleIndex, 1)
    },
    setMeaningIndexToShowAddExampleForm (state, index) {
      state.meaningIndexToShowAddExampleForm = index
    },
    setTranslationIndexToShowAddExampleForm (state, translationIndex) {
      state.translationIndexToShowAddExampleForm = translationIndex
    },
    setTranslationLanguageCodeToShowAddExampleForm (state, languageCode) {
      state.translationLanguageCodeToShowAddExampleForm = languageCode
    },
    updateTranslation (
      state,
      { language, newLanguage, translationIndex, newTranslation }
    ) {
      const oldLanguageTranslations = state.translations[language]
      const newLanguageTranslations = state.translations[newLanguage] || []

      if (!oldLanguageTranslations && language === newLanguage) {
        return console.error('Language translations not found')
      }

      if (language === newLanguage) {
        oldLanguageTranslations.splice(translationIndex, 1, newTranslation)
      } else {
        if (oldLanguageTranslations) {
          oldLanguageTranslations.splice(translationIndex, 1)
        }
        state.translations[newLanguage] = [
          ...newLanguageTranslations,
          newTranslation
        ]
      }
    },
    updateTranslationExample (
      state,
      { language, translationIndex, exampleIndex, newExample }
    ) {
      const languageTranslations = state.translations[language]

      if (languageTranslations) {
        const translation = languageTranslations[translationIndex]

        if (translation) {
          translation.examples.splice(exampleIndex, 1, newExample)
        } else {
          console.error('Translation not found')
        }
      } else {
        console.error('Language translations not found')
      }
    }
  }
}
