import { sendRequest } from '@/services'

export default class DictionaryService {
  static async search (query) {
    const url = new URL(`${process.env.VUE_APP_API_URL}/search`)
    url.searchParams.append('q', query)
    return await sendRequest({ url })
  }

  static async get (id) {
    const url = new URL(`${process.env.VUE_APP_API_URL}/words/${id}`)
    return await sendRequest({ url })
  }

  static async add ({ word }) {
    const url = new URL(`${process.env.VUE_APP_API_URL}/words/new`)
    const body = { ...word }
    return await sendRequest({ url, method: 'POST', body, requiresAuth: true })
  }

  static async update ({ word }) {
    const url = new URL(`${process.env.VUE_APP_API_URL}/words/${word.id}`)
    const body = { ...word }
    return await sendRequest({ url, method: 'PUT', body, requiresAuth: true })
  }

  static async delete (wordId) {
    const url = new URL(`${process.env.VUE_APP_API_URL}/words/${wordId}`)
    return await sendRequest({ url, method: 'DELETE', requiresAuth: true })
  }

  static async fetchWordTips (query) {
    const url = new URL(`${process.env.VUE_APP_API_URL}/words/tips`)
    url.searchParams.append('q', query)
    return await sendRequest({ url })
  }
}
