<template>
  <div class="container">
    <h1>{{ t('welcome.title') }}</h1>
    <p>{{ t('welcome.subtitle') }}</p>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { DictionaryService } from '@/services'

export default {
  setup () {
    const { t } = useI18n({ useScope: 'global' })
    const searchTerm = ref('')
    const results = ref([])

    const search = async () => {
      if (searchTerm.value.length > 2) {
        const response = await DictionaryService.search(searchTerm.value)
        results.value = response
      } else {
        results.value = []
      }
    }

    return {
      searchTerm,
      results,
      search,
      t
    }
  }
}
</script>

<style scoped>
.container {
  text-align: center;
  padding: 100px;
}
h1 {
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
}
p {
  font-size: 18px;
  color: #666;
}
</style>
