import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { localeMessages, defaultLocale } from './i18n'
import { createI18n } from 'vue-i18n'
import VueGtag from 'vue-gtag-next'

const messages = Object.assign(localeMessages)

const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: 'ky',
  messages
})

const app = createApp(App)
app.use(store)
app.use(router)
app.use(i18n)
app.use(
  VueGtag,
  {
    property: {
      id: process.env.VUE_APP_MEASUREMENT_ID
    }
  },
  router
)
app.mount('#app')
