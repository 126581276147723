const sendRequest = async ({
  url,
  method = 'GET',
  headers = {},
  body = null,
  requiresAuth = false
}) => {
  const requestOptions = {
    method,
    headers: { ...headers }
  }

  if (requiresAuth) {
    requestOptions.headers.authorizationToken = localStorage.getItem('token')
  }

  if (method !== 'GET') {
    requestOptions.headers['Content-Type'] = 'application/json'
    requestOptions.body = JSON.stringify(body)
  }
  const response = await fetch(url, requestOptions)
  return response.json()
}

export { sendRequest }
